











































import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';

export default {
  name: 'Question501',
  components: {
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    const browserLanguage = navigator.language.split('-')[0]; // Extract the language code
    return {
      inputs: {
        studentAnswer: null,
        language: browserLanguage === 'fr' ? 'fr' : 'en', // Set the initial language based on browser preference
      },
      options: [
        {text: 'Polar', value: 'polar'},
        {text: 'Non-polar', value: 'nonPolar'},
        {text: 'More information required', value: 'moreInfo'},
      ],
      optionsFr: [
        {text: 'Polaire', value: 'polar'},
        {text: 'Non-polaire', value: 'nonPolar'},
        {text: "Plus d'informations sont nécessaires", value: 'moreInfo'},
      ],
    };
  },
  computed: {
    imageName() {
      return '/img/assignments/dibromomethane_LS.png';
    },
  },
};
